<template>
  <div class="helpDetails">
    <div class="helpDetails-title">{{articleCont.title}}</div>
    <div class="helpDetails-cont" v-html="articleCont.body"></div>
  </div>
</template>

<script>
export default {
  name: 'helpDetails',
  data() {
    return {
      articleCont: []
    }
  },
  created() {
    let query = this.$route.query;
    this.watchEvent(query.id)
  },
  methods: {
    watchEvent(id) {
      this.$request.watchArticle({id}).then(res => {
        if(res.data.code == 0) {
          this.articleCont = res.data.data;
        }
      })
    }
  },
}
</script>

<style lang='scss'>
  .helpDetails {
    height: 100%;
    padding: 20px;
    overflow: scroll;
    overflow-x: hidden;
    .helpDetails-title {
      font-size: 18px;
      color: #333;
      font-weight: 600;
      margin-bottom: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    img {
      width: 100%!important;
    }
  }
</style>